<template>
  <div
	  v-if="subscription && email"
	  style="background-color: #0a092d;">

    <div style="height: 63px; background-color: #0a092d; border-bottom: thin solid #282e3e"
         class="d-flex align-center pl-4"
    >

      <h1
        @click="$router.push({path: '/'})"
        style="color: white; font-size: 1.6em; letter-spacing: -1px !important; font-weight: 700 !important; cursor: pointer"
        class="mb-1 font-rubik"
      >
        <span>examenselectivitat</span>
      </h1>
    </div>

    <div style="position: absolute; top: 63px; left: 0; width: 100%; z-index: 1 !important; height: calc(100% - 70px)"
         class="d-flex">
      <svg
        v-if="true"
        width="825" height="718" viewBox="0 0 825 718" fill="none" xmlns="http://www.w3.org/2000/svg"
        class="dweb mx-auto">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M460.508-669.213c83.164-65.049 218-65.049 301.163 0 83.164 65.048 83.164 170.512 0 235.561L611.738-316.378c54.283.128 108.516 16.39 149.933 48.785 83.164 65.049 83.164 170.513 0 235.561L626.549 73.658c49.238 2.786 97.475 18.902 135.122 48.349 83.164 65.049 83.164 170.513 0 235.561l-398.134 311.41c-83.164 65.049-218 65.049-301.164 0-83.163-65.048-83.163-170.512 0-235.561l135.123-105.689c-49.238-2.786-97.475-18.903-135.123-48.35-83.163-65.048-83.163-170.512 0-235.56L212.307-73.458c-54.284-.128-108.516-16.39-149.934-48.785-83.163-65.049-83.163-170.513 0-235.562l398.135-311.409z"
              fill="url(#paint0_linear_1584_43528)"></path>
        <defs>
          <linearGradient id="paint0_linear_1584_43528" x1="412.022" y1="-718" x2="412.022" y2="717.765"
                          gradientUnits="userSpaceOnUse">
            <stop stop-color="#7A87FF"></stop>
            <stop offset="1" stop-color="#7A87FF" stop-opacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div
      v-if="false"
      style="height: 100vh; padding-bottom: 240px; background-color: white"
      class="d-flex flex-column align-center justify-center text-center">
      <v-img src="../../assets/logo.png"
             width="256"
             height="256"
             style="max-height: 300px"
             contain
      ></v-img>
      <h1
        @click="$router.push({path: '/'})"
        style="color: #256EFF; font-size: 3.6em; letter-spacing: -1px !important; font-weight: 700 !important; cursor: pointer"
        class="mb-1 font-rubik"
      >
        <span>examenselectivitat</span>
      </h1>
      <h1
        style="font-weight: 700; font-size: 2.9rem; background-color: #ffb845; z-index: 99 !important;   text-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1) !important;"
        class="font-rubik rounded-lg px-10 white--text mt-5"
      >
        Premium
      </h1>
    </div>

    <div
      style="width: 100%; position: relative; z-index: 99"
    >


      <v-container style="z-index: 99 !important; max-width: 650px !important;"
                   class="pt-12 premium-header text-center">
        <div class="d-flex flex-column">
          <v-img
            width="80"
            height="80"
            src="https://examenselectivitat.cat:3000/api/images/other/check-circle.webp"
            class="mx-auto"
          ></v-img>

          <h1
            style="font-weight: 700; font-size: 3.375rem; color: white; z-index: 99 !important;"
            class="font-rubik my-5"
          >
            Ja ets prèmium!
          </h1>
          <span
            style="font-size: 1.3rem; line-height: 2rem; color: white; font-weight: 400 !important;"
            class="font-rubik"
          >
          La compra ha sigut completada. S'enviarà un rebut de la comanda
            <span v-if="subscription"
                  style="min-width: 350px"
                  class="font-weight-medium"
            >{{ subscription }}</span>
            al vostre correu electrònic (<span style="text-decoration: underline">{{ email }}</span>)
        </span>

          <a :href="url">
            <v-btn
              class="font-rubik text-none mx-auto mt-12 px-12 rounded-lg"
              style="width: fit-content"
              x-large
              height="40"
            >
              Som-hi!
            </v-btn>
          </a>

        </div>

      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessfulPayment",
  async mounted() {
    await this.fetchPaymentIntent()
  },
  data() {
    return {
      subscription: null,
      email: null,
      url: process.env.VUE_APP_URL,
    }
  },
  methods: {
    async fetchPaymentIntent() {
      const {data} = await this.axios.get('/premium/id/' + this.$route.query.session_id)
      this.subscription = data.subscription;
      this.email = data.email;
    }
  }
}
</script>

<style scoped>

</style>
